import React, { useState } from 'react';
import AnchorLink from "react-anchor-link-smooth-scroll";

import './navbar.css'; // Import your CSS file

function OffCanvasMenu() {
  const [sideSlideVisible, setSideSlideVisible] = useState(false);
  const [dropDownVisible, setDropDownVisible] = useState(false);

  const toggleSideSlide = () => {
    setSideSlideVisible(!sideSlideVisible);
  };

  const toggleDropDown = () => {
    setDropDownVisible(!dropDownVisible);
  };

  return (
    <div className="container-fluid navbar-container">
      <main>
        <hgroup>
          <button className={`btn01 ${sideSlideVisible ? 'active' : ''}`} onClick={toggleSideSlide}>
            {sideSlideVisible ? (
              // Close icon SVG
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M18.364 18.364a.5.5 0 0 1-.707 0L12 12.707l-5.657 5.657a.5.5 0 0 1-.707-.707L11.293 12 5.636 6.343a.5.5 0 0 1 .707-.707L12 11.293l5.657-5.657a.5.5 0 0 1 .707.707L12.707 12l5.657 5.657a.5.5 0 0 1 0 .707z" fill="black" />
              </svg>
            ) : (
              // Hamburger icon
              <div className="menu-icon">
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </div>
            )}
          </button>
        </hgroup>
      </main>

      <nav className={`side-slide ${sideSlideVisible ? 'visible' : ''}`}>
        {/* <h3 className="nav01" onClick={toggleSideSlide}>x</h3> */}
        <button className={`btn01 ${sideSlideVisible ? 'active' : ''}`} onClick={toggleSideSlide}>
          {sideSlideVisible ? (
            // Close icon SVG
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M18.364 18.364a.5.5 0 0 1-.707 0L12 12.707l-5.657 5.657a.5.5 0 0 1-.707-.707L11.293 12 5.636 6.343a.5.5 0 0 1 .707-.707L12 11.293l5.657-5.657a.5.5 0 0 1 .707.707L12.707 12l5.657 5.657a.5.5 0 0 1 0 .707z" fill="black" />
            </svg>
          ) : (
            // Hamburger icon
            <div className="menu-icon">
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
          )}
        </button>
        <ul>
          {/* <li><AnchorLink href="#why-hinjawadi">Why Hinjawadi</AnchorLink></li> */}
          <li><a href="#home">Home</a></li>
          <li><a href="#why-hinjawadi">Why Hinjawadi</a></li>
          <li><a href="#location-advantages">Location Advantages</a></li>
          <li><a href="#community">Community of Exclusives & NRIs</a></li>
          <li><a href="#managed-forest">Managed Forest Concept</a></li>
          <li><a href="#work-from-forest">Work From Forest</a></li>
          <li><a href="#telepresence-room">Telepresence Room</a></li>
          <li><a href="#amenities">Amenities</a></li>
          <li><a href="#contact-us">Contact Us</a></li>
        </ul>
      </nav>
    </div>
  );
}

export default OffCanvasMenu;
