import logo from './logo.svg';
import './App.css';
import ImageBanner from '../src/Banner/banner';
import 'bootstrap/dist/css/bootstrap.min.css';
import WhyHinjawadi from './WhyHinjawadi/whyhinjawadi';
import Community from './Community/community';
import ContactForm from './Contact/contact';
import ManagedForest from './ManagedForest/managedforest';
import WorkFromForest from './WorkFromForest/workfromforest';
import Amenities from './Amenities/amenities';
import Footer from './Footer/footer';
import ReraCompliance from './ReraCompliance/reracompliance';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Otherpage from './Otherpage';
import ThankYouBanner from './ThankYouBanner/thankyoubanner';
import Location from './Location/location';
import React from 'react';
import TagManager from 'react-gtm-module';
import TelepresenceRoom from './TelepresenceRoom/telepresenceroom';
import WhatsAppButton from './WhatsApp/WhatsAppButton';

const tagManagerArgs = {
  gtmId: 'WZF2PBZF'
};



TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <div className="App">
    
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<>
          <meta name="google-site-verification" content="3wLN5RIsPCwLQGQjbVepvvVwiz85R6TtLNlz5_yWimU" />
            <ImageBanner />
            <WhyHinjawadi />
            <Location />
            <Community />
            <ManagedForest />
            <WorkFromForest />
            <TelepresenceRoom />
            <Amenities />
            <ContactForm />
            <ReraCompliance />
            <WhatsAppButton />
            <Footer />
          </>} />
          <Route path="/thankyou" element={<ThankYouBanner />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
