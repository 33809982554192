import React from 'react';
import './location.css'; // Import the CSS file
import LocationIcon from '../LocationIcon/locationicon'


const locations = [
    { distance: '700m', name: 'Upcoming Ring Road' },
    { distance: '6km', name: 'Hinjewadi Metro Station' },
    { distance: '6.5km', name: 'TCS Hinjewadi Phase 3 ' },
    { distance: '1.5km', name: 'Orchid School' },
    { distance: '6.5km', name: 'Pawar Public School' },
    { distance: '12.7km', name: 'Symbiosis University' },
    { distance: '11.4km', name: 'Grand Highstreet' },
    { distance: '6km', name: 'Rihegaon Dam' },
    // Add more amenities as needed
];

const Location = () => {
    return (
        <div id="location-advantages">

            <div className='background-container'>
                <h1>Prime Location Just 5 km from Hinjewadi </h1>
                <h2>REACH US WITHOUT A FUSS TO DISCOVER</h2>
                <h3>Our Residential NA Plots and Bungalows</h3>
                <p className='contact-p'>
                    Immerse yourself in the vibrant energy of Pune's tech hub at Sylvanscape. Experience the convenience of urban living <br/> amidst lush greenery, offering residents the best of both RERA registered plots and PMRDA sanctioned residential projects.
                </p>
                <div className="amenities-grid-container">
                    {locations.map((location, index) => (
                        <div key={index} className="amenities-grid-item">
                            <LocationIcon distance={location.distance} name={location.name} alt='Plotting project in Pune' />
                        </div>
                    ))}
                </div>

                <div class="map-wrap">
                    <h2>Google Location Map</h2>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15131.45070929566!2d73.6708647!3d18.5351067!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bd007a763f55%3A0x850c6a1ac0c05e28!2sSylvanscape!5e0!3m2!1sen!2sin!4v1712833877211!5m2!1sen!2sin" width="100%" height="450" style={{ border: "2px solid green" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
            </div>
        </div>
    );
};

export default Location;
