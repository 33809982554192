import React from 'react'
import { useState } from 'react';
import '../WhyHinjawadi/whyhinjawadi.css'
import hinjawadi from '../WhyHinjawadi/WhyHinjawadiImage.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import mobileResp from '../WhyHinjawadi/respHinjawadi.png'
function WhyHinjawadi() {
  const data = [
    { id: 0, label: "Hinjawadi, the coveted IT hub of Pune, is not just witnessing development; it's driving it. With Tech Mahindra a mere 10 minutes away from Sylvanscape, you're not just investing in a plot; You're investing in proximity to employment centers, ensuring a high ROI." }
  ];

  const [isOpen1, setOpen1] = useState(false);
  const [isOpen2, setOpen2] = useState(false);
  const [isOpen3, setOpen3] = useState(false);
  const [items1, setItems1] = useState(data);
  const [items2, setItems2] = useState(data);
  const [items3, setItems3] = useState(data);
  const [selectedItem1, setSelectedItem1] = useState(null);
  const [selectedItem2, setSelectedItem2] = useState(null);
  const [selectedItem3, setSelectedItem3] = useState(null);

  const toggleDropdown1 = () => {
    setOpen1(!isOpen1);
    setOpen2(false);
    setOpen3(false);
  };

  const toggleDropdown2 = () => {
    setOpen2(!isOpen2);
    setOpen1(false);
    setOpen3(false);
  };

  const toggleDropdown3 = () => {
    setOpen3(!isOpen3);
    setOpen1(false);
    setOpen2(false);
  };

  const handleItemClick1 = (id) => {
    setSelectedItem1(selectedItem1 === id ? null : id);
  }

  const handleItemClick2 = (id) => {
    setSelectedItem2(selectedItem2 === id ? null : id);
  }

  const handleItemClick3 = (id) => {
    setSelectedItem3(selectedItem3 === id ? null : id);
  }
  return (
    <div id="why-hinjawadi">
      {/* <div className='container-fluid imgD hideM'>
        
        <img src={hinjawadi} alt="Your Image" className="image hinjawadiImage" width={'100%'}/>
    </div> */}
      <div className='container hinjawadiOverlap hideDrp'>
        <center>
          <div class="why-hinjawadi-intro">
            <h3>WHY HINJEWADI</h3>
            <h2 style={{ textDecoration: 'underline', textDecorationThickness: '3px', textUnderlineOffset: '10px', lineHeight: '1.75' }}>Hinjewadi: THE SMART INVESTMENT HUB</h2>
            <p>Investing in a plot near Hinjewadi is more than a choice; it's a strategic move.</p>
            <p>Sylvanscape offers a range of PMRDA sanctioned residential projects near Hinjewadi, including RERA registered plots and NA plots near Pune. This region is renowned for its high-end living options, making it a sought-after location for both residential and investment opportunities. </p>
          </div>
          <div className='dropdown'>
            <div className='dropdown-header' onClick={toggleDropdown1}>
              {selectedItem1 ? items1.find(item => item.id === selectedItem1).label : "Strategic Location"}
              <FontAwesomeIcon icon={faChevronRight} className={`icon ${isOpen1 && "open"}`} />
            </div>
            <div className={`dropdown-body ${isOpen1 && 'open'}`}>
              {items1.map(item => (
                <div className="dropdown-item" onClick={() => handleItemClick1(item.id)} key={item.id}>
                  <p style={{ "textWrap": "balance" }}>Hinjawadi, the coveted IT hub of Pune, is not just witnessing development; it's driving it. With Tech Mahindra a mere 10 minutes away from Sylvanscape, you're not just investing in a plot; You're investing in proximity to employment centers, ensuring a high ROI.</p>
                </div>
              ))}
            </div>
          </div><br />

          <div className='dropdown'>
            <div className='dropdown-header' onClick={toggleDropdown2}>
              {selectedItem2 ? items2.find(item => item.id === selectedItem2).label : "Future-Ready Connectivity"}
              <FontAwesomeIcon icon={faChevronRight} className={`icon ${isOpen2 && "open"}`} />
            </div>
            <div className={`dropdown-body ${isOpen2 && 'open'}`}>
              {items2.map(item => (
                <div className="dropdown-item" onClick={() => handleItemClick2(item.id)} key={item.id}>
                  {/* <span className={`dropdown-item-dot ${item.id === selectedItem2 && 'selected'}`}>• </span> */}
                  <p style={{ "textWrap": "balance" }}>Sylvanscape is strategically located very near the upcoming ring road and Hinjawadi Metro Station. This ensures that your investment is future-ready, with easy access to key areas of Pune. The Hinjawadi boom is not just a trend; it's a sustained trajectory of growth.</p>
                </div>
              ))}
            </div>
          </div><br />

          <div className='dropdown'>
            <div className='dropdown-header' onClick={toggleDropdown3}>
              {selectedItem3 ? items3.find(item => item.id === selectedItem3).label : "High ROI Anticipation"}
              <FontAwesomeIcon icon={faChevronRight} className={`icon ${isOpen3 && "open"}`} />
            </div>
            <div className={`dropdown-body ${isOpen3 && 'open'}`}>
              {items3.map(item => (
                <div className="dropdown-item" onClick={() => handleItemClick3(item.id)} key={item.id}>
                  {/* <span className={`dropdown-item-dot ${item.id === selectedItem3 && 'selected'}`}>• </span> */}
                  <p style={{ "textWrap": "balance" }}>The Hinjawadi region is experiencing unprecedented growth. With limited plots available in the region, Sylvanscape offers a unique opportunity for high returns on investment. Investing in a plot here means aligning with progress and securing your place in one of Pune's most sought-after locations.</p>
                </div>
              ))}
            </div>
          </div>
        </center>
      </div>
      <div className='container-fluid imgD hideM'>

        <img src={hinjawadi} alt="NA Plots near Pune" className="image hinjawadiImage" width={'100%'} />
      </div>
      {/* <div className='container-fluid mobileHinjawadi imgD'>
        <img src={mobileResp} alt="Your Image" className="image hinjawadiMobile" width={'100%'}/>
    </div> */}
      <div className='container hinjawadiOverlap hideMobile'>
        <center>
          <div class="why-hinjawadi-intro">
            <h3>WHY HINJEWADI</h3>
            <h2 style={{ textDecoration: 'underline', textDecorationThickness: '3px', textUnderlineOffset: '10px', lineHeight: '1.75' }}>Hinjawadi: THE SMART INVESTMENT HUB</h2>
            <p>Investing in a plot near Hinjewadi is more than a choice; it's a strategic move.</p>
            <p>Sylvanscape offers a range of PMRDA sanctioned residential projects near Hinjewadi, including RERA registered plots and NA plots near Pune. This region is renowned for its high-end living options, making it a sought-after location for both residential and investment opportunities. </p>

          </div>

          <div className='dropdown'>
            <div className='dropdown-header' onClick={toggleDropdown1}>
              {selectedItem1 ? items1.find(item => item.id === selectedItem1).label : "Strategic Location"}
              <FontAwesomeIcon icon={faChevronRight} className={`icon ${isOpen1 && "open"}`} />
            </div>
            <div className={`dropdown-body ${isOpen1 && 'open'}`}>
              {items1.map(item => (
                <div className="dropdown-item" onClick={() => handleItemClick1(item.id)} key={item.id}>
                  {/* <span className={`dropdown-item-dot ${item.id === selectedItem1 && 'selected'}`}>• </span> */}
                  {/* <p style={{ fontSize: '15px' }}>Hinjawadi, the coveted IT hub of Pune,<br />is not just witnessing development; it's<br />driving it. With Tech Mahindra a mere 10<br />minutes away from Sylvanscape, you're<br />not just investing in a plot; You're<br />investing in proximity to employment<br />centers, ensuring a high ROI.</p> */}
                  <p style={{ textWrap: "balance", fontSize: "15px" }}>Hinjawadi, the coveted IT hub of Pune, is not just witnessing development; it's driving it. With Tech Mahindra a mere 10 minutes away from Sylvanscape, you're not just investing in a plot; You're investing in proximity to employment centers, ensuring a high ROI.</p>

                </div>
              ))}
            </div>
          </div><br />

          <div className='dropdown'>
            <div className='dropdown-header' onClick={toggleDropdown2}>
              {selectedItem2 ? items2.find(item => item.id === selectedItem2).label : "Future-Ready Connectivity"}
              <FontAwesomeIcon icon={faChevronRight} className={`icon ${isOpen2 && "open"}`} />
            </div>
            <div className={`dropdown-body ${isOpen2 && 'open'}`}>
              {items2.map(item => (
                <div className="dropdown-item" onClick={() => handleItemClick2(item.id)} key={item.id}>
                  {/* <span className={`dropdown-item-dot ${item.id === selectedItem2 && 'selected'}`}>• </span> */}
                  <p style={{ fontSize: '15px' }}>Sylvanscape is strategically located<br />very near the upcoming ring road and<br />Hinjawadi Metro Station. This ensures<br />that your investment is future-ready,<br />with easy access to key areas of Pune.<br />The Hinjawadi boom is not just a trend;<br />it's a sustained trajectory of growth.</p>
                </div>
              ))}
            </div>
          </div><br />

          <div className='dropdown'>
            <div className='dropdown-header' onClick={toggleDropdown3}>
              {selectedItem3 ? items3.find(item => item.id === selectedItem3).label : "High ROI Anticipation"}
              <FontAwesomeIcon icon={faChevronRight} className={`icon ${isOpen3 && "open"}`} />
            </div>
            <div className={`dropdown-body ${isOpen3 && 'open'}`}>
              {items3.map(item => (
                <div className="dropdown-item" onClick={() => handleItemClick3(item.id)} key={item.id}>
                  {/* <span className={`dropdown-item-dot ${item.id === selectedItem3 && 'selected'}`}>• </span> */}
                  <p style={{ fontSize: '15px' }}>The Hinjawadi region is experiencing<br />unprecedented growth. With limited plots<br />available in the region, Sylvanscape<br />offers a unique opportunity for high<br />returns on investment. Investing in a plot<br />here means aligning with progress and<br />securing your place in one of Pune's most<br />sought-after locations.</p>
                </div>
              ))}
            </div>
          </div>
        </center>
      </div>
      <div className='container-fluid mobileHinjawadi imgD'>
        <img src={mobileResp} alt="NA Plots near Pune" className="image hinjawadiMobile" width={'100%'} />
      </div>
      {/* <div>
        <h1>Hello</h1>
    </div> */}
    </div>
  )
}

export default WhyHinjawadi