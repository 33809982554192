import React, { useState } from 'react';
import './reracompliance.css'; // Import the CSS file
import P52100054087 from './P52100054087.png'
import P52100055153 from './P52100055153.png'

const ReraCompliance = () => {

    return (
        <div id="about-us">

            <div className='rera-background-container'>
                <h1 className='rera-h1'>Rera Compliance</h1>
                {/* <h2 className='rera-h2'>Transform Your Work Environment</h2> */}
                <p className='rera-p'>
                    <b>Sylvanscape</b> is a project committed to excellence and legal transparency. <br />As a RERA registered project with the number <b>P52100054087</b> and <b>P52100055153</b>, we assure our buyers of a project that meets all regulatory standards, providing peace of mind and confidence in your investment. <br />More details are available at https://maharera.maharashtra.gov.in
                </p>
                <div className='rera-qr-container'>
                    <img src={P52100054087} alt="RERA Registered Plots near Hinjewadi" className="image hinjawadiImage" width={'100px'} />
                    {/* <div class="div1"><img src={P52100055153} alt="Your Image" className="image hinjawadiImage" width={'100px'} /></div> */}
                    <div class="div2"><span>P52100054087</span></div>
                    <img src={P52100055153} alt="NA Plots near Pune" className="image hinjawadiImage" width={'100px'} />
                    {/* <div class="div3"><img src={P52100055153} alt="Your Image" className="image hinjawadiImage" width={'100px'} /></div> */}
                    <div class="div4"><span>P52100055153</span></div>
                </div>
                <br />
                <p className='rera-p'>
                    Sylvanscape has been approved for financing by ICICI Bank
                </p>
            </div>
        </div>
    );
};

export default ReraCompliance;
