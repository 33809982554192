    import React from 'react'
import '../ManagedForest/managedforest.css'
import carImage from '../ManagedForest/ManagedForestConceptFinalImage.png'

function ManagedForest() {
  return (
    <div id="managed-forest">
      <div className='managed-forest-container'>
        <div className='container-fluid carContainer desktopView'>


          <div className='row greenGradient imgD'>
            <div className='col-lg-12 imgD'>
              <img src={carImage} alt="RERA Registered Plots near Hinjewadi" className="image carImage" width={'50%'} style={{ float: 'right' }} />
              <div className='cartextOverlay'>
                <h3>MANAGED FOREST CONCEPT</h3>
                <h2 style={{ textDecoration: 'underline', textDecorationThickness: '3px', textUnderlineOffset: '10px', lineHeight: '2' }}>A GREEN HAVEN<br />WITH THOUGHTFUL DESIGN</h2>
              </div>
              <div className='carTextOverlay'>
                {/* <p>At Sylvanscape, our vision transcends traditional residential development. We aspire to curate a haven for the discerning - an exclusive community for Exclusives and NRIs seeking a graceful lifestyle.<br />Join a community that mirrors your refined taste. At Sylvanscape, each villa plot is a canvas for your dream home, where nature blends seamlessly with opulence.<br />Sylvanscape is not just a residential project; it's a vision brought to life for those who seek a life of grace and tranquility.</p> */}
                <p>Sylvanscape's path breaking Managed Forest Concept is a symphony of nature and design. With precision planning, we've created an environment where 7000+ trees coexist seamlessly with your living space. <br /> Our residential NA plots near Pune are designed to bring you closer <br /> to nature while
enjoying the conveniences of urban life. </p><br />
                
                <p>Each tree is strategically placed to ensure that your home receives ample sunlight, fresh air, and unobstructed views of nature. The result is a living space where the beauty of the forest enhances your lifestyle, creating a serene and refreshing atmosphere every day.<br /></p><br /><br />

              </div>
              
            </div>
          </div>

        </div>
        <div className='container-fluid carContainer mobileView'>


          <div className='row greenGradient imgD'>
            <div className='col-lg-12 imgD'>
              <img src={carImage} alt="RERA Registered Plots near Hinjewadi" className="image carImage" width={'50%'} style={{ float: 'right' }} />
              <div className='cartextOverlay'>
                <h3>MANAGED FOREST CONCEPT</h3>
                <h2 style={{ textDecoration: 'underline', textDecorationThickness: '2.5px', textUnderlineOffset: '5px' }}>A GREEN HAVEN WITH THOUGHTFUL DESIGN</h2><br />
              </div><center>
                <div className='carTextOverlay'>
                  {/* <p>At Sylvanscape, our vision transcends traditional residential development. We aspire to curate a haven for the discerning - an exclusive community for Exclusives and NRIs seeking a graceful lifestyle.<br />Join a community that mirrors your refined taste. At Sylvanscape, each villa plot is a canvas for your dream home, where nature blends seamlessly with opulence.<br />Sylvanscape is not just a residential project; it's a vision brought to life for those who seek a life of grace and tranquility.</p> */}
                  <p>Sylvanscape's path breaking Managed Forest Concept is a symphony of nature and design. With precision planning, we've created an environment where 7000+ trees coexist seamlessly with your living space. <br /> Our residential NA plots near Pune are designed to bring you closer <br /> to nature while
enjoying the conveniences of urban life. </p><br />
                  
                  <p>Each tree is strategically placed to ensure that your home receives ample sunlight, fresh air, and unobstructed views of nature. The result is a living space where the beauty of the forest enhances your lifestyle, creating a serene and refreshing atmosphere every day.<br /></p><br /><br />

                </div></center>

            </div>
          </div>

        </div>
      </div >
    </div >
  )
}

export default ManagedForest