import React from 'react';
import bannerImage from '../ThankYouBanner/HeroImages.png';
import OffCanvasMenu from '../Navbar/navbar';
import '../ThankYouBanner/thankyoubanner.css';
import logo from '../ThankYouBanner/SylvanscapeLogo.png';
import logo2 from '../ThankYouBanner/SylvanscapeLogo1.png';
import Overview from '../ThankYouBanner/OverviewBGFinalImage.png';
import mobOverview from '../ThankYouBanner/OverviewImage.png';

// import Overview from '../ThankYouBanner/OverviewBGFinalImage.png';
// import Hinjawadi from '../WhyHinjawadi/whyHinjawadi';
// import hinjawadi from '../WhyHinjawadi/WhyHinjawadiImage.png'

function ThankYouBanner() {
  return (
    <div id="home">

      <div className='container-fluid'>
        <div className='row position-relative'>

          <div className='col-lg-6 imgD'>
            {/* <OffCanvasMenu className="position-absolute top-0 start-0" /> */}

            <img
              className="IconcardImage"
              src={bannerImage}
              alt="Bungalows near Pune"
              width={'100%'}
            />
          </div>
          <div className='col-lg-6 image-container'>
            {/* <div className='bookasitevisit'>
                <h6>Book A Site Visit</h6>
            </div>
            <div className='welcometo'>
                <h3>Welcome To</h3>
                <img
                    className="IconcardImage"
                    src={logo} 
                    alt=""
                    width={'100%'}
                />  
            </div> */}
            {/* <h6 className="text-overlay1">Book A Site Visit</h6> */}
            <h1 className="text-overlay2">Thank You For Contacting Sylvanscape</h1>

            {/* <img src={logo} alt="Your Image" className="image" width={'100%'} /> */}
            <div className="text-overlay">Your Ultimate Destination For Premium <br />Bungalows and Plots In Pune!</div>
          </div>
        </div>
        {/* <div className='container hideGrid'>
          <center>
            <div class="grid-container">
              <div class="grid-item"><p>MANAGED FOREST STAY COMPRISING<br /></p><h1>7000+ TREES</h1></div>
              <div class="grid-item"><p>COMMUNITY FOR<br /></p><h1> EXCLUSIVEs & NRIs</h1></div>
            </div>
          </center>
        </div> */}
        <div className="text-overlayMob hide1">Thank You For Contacting Sylvanscape<br />
          <img src={logo2} alt="Bungalows near Hinjewadi" className="respImage" width={'100%'} />
          <p style={{ fontSize: '15px' }}>Your Ultimate Destination For Premium <br />Bungalows and Plots In Pune!</p>
          {/* <p style={{ fontSize: '15px' }}><b>STARTING PRICE</b></p> */}
          {/* <h4 style={{ fontSize: '35px' }}>Rs 52L*</h4> */}
          <div class="container1" style={{ border: '2px solid white', borderRadius: '10px' }}>
            <div class="partition1" style={{ borderRight: '2px solid white', height: '100%' }}>
              {/* <h2>Partition 1</h2> */}
              <p style={{ fontSize: '10px' }}>MANAGED FOREST STAY COMPRISING</p>
              <h6><b>7000+ TREES</b></h6>

            </div>
            <div class="partition1">
              {/* <h2>Partition 2</h2> */}
              <p style={{ fontSize: '10px' }}>COMMUNITY FOR</p>
              <h6><b>EXCLUSIVEs &NRIs</b></h6>


            </div>
          </div>
        </div>

      </div>
      {/* <div className="text-overlay">Your Ultimate Destination For Premium <br />Bungalows and Plots In Pune!</div> */}

    </div>
  );
}

export default ThankYouBanner;
