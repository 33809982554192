import React, { useState } from 'react';
import './telepresenceroom.css'; // Import the CSS file

const TelepresenceRoom = () => {

    return (
        <div id="telepresence-room" className="telepresenceGradient">

            <div className='telepre-background-container'>
                <h1 className='telepre-h1'>TELEPRESENCE ROOM : Connecting Beyond Boundaries</h1>
                <h2 className='telepre-h2'>A dedicated telepresence room — where distance is no barrier to connection.</h2>
                <h4 className='telepre-h4'>Business and Family Connections:</h4>
                <p className='telepre-p'>
                    Our telepresence room serves as an ideal setting for both business conferencing and personal interactions, fostering meaningful connections.
                </p>
                <h4 className='telepre-h4'>High-Definition Telepresence Setup:</h4>
                <p className='telepre-p'>
                    Enjoy crisp and clear visuals with our large telepresence screen, HD camera, and top-notch audio system, ensuring an immersive communication experience.
                </p>
                <h4 className='telepre-h4'>Privacy Guaranteed:</h4>
                <p className='telepre-p'>
                    Conduct meetings undisturbed, ensuring privacy and confidentiality.
                </p>
                {/* <img src="../TelepresenceRoom/TelepresenceRoomImage.png" alt="" className="telepresence-image" /> */}
                <img src="/TelepresenceRoomImage.png" alt="Residential NA Plots near Hinjewadi" className="telepresence-image" />

            </div>
        </div>
    );
};

export default TelepresenceRoom;
