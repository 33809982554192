import React from 'react'
import ImageBanner from './Banner/banner'
import WhyHinjawadi from './WhyHinjawadi/whyhinjawadi'
import Community from './Community/community'
import ManagedForest from './ManagedForest/managedforest'
import WorkFromForest from './WorkFromForest/workfromforest'
import Amenities from './Amenities/amenities'
import ReraCompliance from './ReraCompliance/reracompliance'
import Footer from './Footer/footer'
import ContactForm from './Contact/contact';


function Otherpage() {
    return (
        <div>
            <div id="home">
                <ImageBanner />
            </div>
            {/* <PerfectPlot /> */}
            <div id="why-hinjawadi">
                <WhyHinjawadi />
            </div>
            <div id="community">
                <Community />
            </div>
            <div id="managed-forest">
                <ManagedForest />
            </div>
            <div id="work-from-forest">
                <WorkFromForest />
            </div>
            <div id="amenities">
                <Amenities />
            </div>
            <div id="contact-us">
                <ContactForm />
            </div>
            <div id="about-us">
                <ReraCompliance />
            </div>
            <Footer />
        </div>
    )
}

export default Otherpage