import React, { useState } from 'react';
import './workfromforest.css'; // Import the CSS file

const WorkFromForest = () => {

    return (
        <div id="work-from-forest" className="wffGradient">

            <div className='wff-background-container'>
                <h1 className='wff-h1'>WORK FROM FOREST</h1>
                <h2 className='wff-h2'>Transform Your Work Environment</h2>
                <p className='wff-p'>
                    Why settle for a traditional office when you can work in the tranquility of nature?<br />
                    Experience the future of remote work at Sylvanscape, where our residential <br />
projects near Hinjewadi are designed with the future in mind. The "Work From <br />
Forest" concept lets you work surrounded by the beauty of nature in our <br />
exclusive glass pods, providing a 360-degree immersive view ensuring an <br />
inspiring and tranquil work atmosphere.
                </p>
                <img src="/WorkFromForest.png" alt="RERA plots near Hinjewadi" className="telepresence-image" />

            </div>
        </div>
    );
};

export default WorkFromForest;
