import React from 'react';
import './amenityicon.css'; // Import the CSS file

const AmenityIcon = ({ icon, name }) => {
    return (
        <div className="amenity">
            {/* <div className="circle"> */}
            <img src={icon} alt='RERA registered plots near Pune' />
            {/* </div> */}
            <hr className="separator" />
            <div className="name">{name}</div>
        </div>
    );
};

export default AmenityIcon;
