import React from 'react';
import bannerImage from '../Banner/HeroImages.png';
import OffCanvasMenu from '../Navbar/navbar';
import '../Banner/banner.css';
import logo from '../Banner/SylvanscapeLogo.png';
import logo2 from '../Banner/SylvanscapeLogo1.png';
import Overview from '../Banner/OverviewBGFinalImage.png';
import mobOverview from '../Banner/OverviewImage.png';
import offerBanner from './offer_banner.jpeg'

// import Overview from '../Banner/OverviewBGFinalImage.png';
// import Hinjawadi from '../WhyHinjawadi/whyHinjawadi';
// import hinjawadi from '../WhyHinjawadi/WhyHinjawadiImage.png'

function ImageBanner() {
  return (
    <div id="home">

      <div className='container-fluid'>
        <div className='row position-relative'>

          <div className='col-lg-6 imgD'>
            <OffCanvasMenu className="position-absolute top-0 start-0" />

            <img
              className="IconcardImage"
              src={bannerImage}
              alt="NA Plots near Pune"
              width={'100%'}
            />
          </div>
          <div className='col-lg-6 image-container'>
            {/* <div className='bookasitevisit'>
                <h6>Book A Site Visit</h6>
            </div>
            <div className='welcometo'>
                <h3>Welcome To</h3>
                <img
                    className="IconcardImage"
                    src={logo} 
                    alt=""
                    width={'100%'}
                />  
            </div> */}
            {/* <h6 className="text-overlay1">Book A Site Visit</h6> */}
            <h1 className="text-overlay2">Welcome To</h1>

            <img src={logo} alt="RERA registered plots" className="image" width={'100%'} />
            <div className="text-overlay">Your Ultimate Destination For Premium Bungalows and Residential
NA Plots, near Hinjewadi In Pune!</div>
          </div>
        </div>
        
       
        
        <div className='container hideGrid'>
          <center>
            <div class="grid-container">
              <div class="grid-item"><p>MANAGED FOREST STAY COMPRISING<br /></p><h1>7000+ TREES</h1></div>
              <div class="grid-item"><p>COMMUNITY FOR<br /></p><h1> EXCLUSIVEs & NRIs</h1></div>
              {/* <div class="grid-item1"><p>STARTING PRICE<br /></p><h1> RS 52L* </h1></div> */}
            </div>
          </center>
        </div>
        <div className="text-overlayMob hide1">WELCOME TO<br />
          <img src={logo2} alt="RERA registered plots " className="respImage" width={'100%'} />
          <p style={{ fontSize: '15px' }}>Your Ultimate Destination For Premium <br />Bungalows and Plots In Pune!</p>
          {/* <p style={{ fontSize: '15px' }}><b>STARTING PRICE</b></p> */}
          {/* <h4 style={{ fontSize: '35px' }}>Rs 52L*</h4> */}
          <div class="container1" style={{ border: '2px solid white', borderRadius: '10px' }}>
            <div class="partition1" style={{ borderRight: '2px solid white', height: '100%' }}>
              {/* <h2>Partition 1</h2> */}
              <p style={{ fontSize: '10px' }}>MANAGED FOREST STAY COMPRISING</p>
              <h6><b>7000+ TREES</b></h6>

            </div>
            <div class="partition1">
              {/* <h2>Partition 2</h2> */}
              <p style={{ fontSize: '10px' }}>COMMUNITY FOR</p>
              <h6><b>EXCLUSIVEs &NRIs</b></h6>

            </div>
          </div>
        </div>

      </div>
      <div className='container-fluid imgD'>
        <div className="text-overlay-overview" style={{ fontSize: '25px', lineHeight: '1.75' }}>Are you in search of the perfect bungalow or plot for sale near Hinjewadi, Pune? <br />Look no further.<br />Sylvanscape presents an exquisite collection of luxury villa and villa plots in a gated community near Hinjewadi Phase 3, Pune. <br />Our strategically located bungalows and plots offer not just a piece of land<br /> but an opportunity to embrace a lifestyle of <br />unparalleled elegance and natural beauty.<br />Luxury, privacy, and security come together<br />in our NA residential bungalows and plots,<br />providing a serene and luxurious<br />living experience.</div>
        <img src={Overview} alt="residential projects near hinjewadi" className="image OverviewImage" width={'100%'} />
      </div>
      <div className='container-fluid imageBg imgD'>
        <div className='greenGradient'>
          <div className="text-overlay-overviewMob" style={{ fontSize: '20px', color: 'white' }}>Are you in search of the perfect bungalow or plot for sale near Hinjewadi, Pune? <br />Look no further.<br />Sylvanscape presents an exquisite collection of luxury villa and villa plots in a gated<br />community near Hinjewadi Phase 3, Pune. Our strategically located bungalows and plots<br />offer not just a piece of land but an opportunity to embrace a<br />lifestyle of unparalleled elegance and natural beauty.<br />Luxury, privacy, and security come together<br />in our NA residential bungalows and plots,<br />providing a serene and luxurious<br />living experience.</div>
          <img src={mobOverview} alt="Buy plots in Hinjewadi " className="image OverviewImageMob" width={'100%'} />
        </div>
      </div>
      {/* <div className='container newContainer'>
          <div className='bnrContainer'>
            <h1>WELCOME TO</h1>
            <center>
            <img src={logo} alt="Your Image" className="respImage" width={'100%'}/>
            </center>
          </div>
    </div> */}
      {/* <div className='container'>
        <center>
    <div class="grid-container">
    <div class="grid-item"><p>MANAGED FOREST STAY COMPRISING<br /></p><h1>7000+ TREES</h1></div>
    <div class="grid-item"><p>COMMUNITY FOR<br /></p><h1> EXCLUSIVES & NRIs</h1></div>
    <div class="grid-item1"><p>STARTING PRICE<br /></p><h1> RS 52L* </h1></div>  
    
    </div>
    </center>
    </div> */}
    
     <div className="offerBanner">
            <img  src={offerBanner} alt="ganeshOffer" />
        </div>

    </div>
  );
}

export default ImageBanner;
