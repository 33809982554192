import React from 'react'
import './WhatsAppButton.css'
import whatsAppLogo from './whatsapp.png'

const WhatsAppButton = () => {
    const phoneNumber = '+917219191500';
    const message = 'Hello, I would like to know more about your services!';

    const handleClick = () => {
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}` ;
        window.open(whatsappURL, '_blank');
    } 

    return(
        <>
            <div className="whatsapp-container" onClick={handleClick} >
            <img src={whatsAppLogo} alt="WhatsApp-Logo" className="whtsAp" />
            </div>
        </>
    );
}
export default WhatsAppButton;