import React from 'react';
import AmenityIcon from '../AmenityIcon/amenityicon'; // Import the Amenity component
import './amenities.css'; // Import the CSS file



const amenities = [
    { icon: "/AmenityIconImages/CCTV.png", name: 'CCTV', alt: 'Plotting project in Pune'},
    { icon: "/AmenityIconImages/WorkingPodIcon.png", name: 'Working Pods', alt: 'PMRDA sanctioned Plots' },
    { icon: "/AmenityIconImages/WallCompoundIcon.png", name: 'Compound Wall', alt: 'RERA Registered Plots near Hinjewadi' },
    { icon: "/AmenityIconImages/CementRoadIcon.png", name: 'Cement Roads', alt: 'Residential Plots'},
    { icon: "/AmenityIconImages/ElectricityConnection.png", name: 'Electricity Connection', alt: 'RERA registered plots'},
    { icon: "/AmenityIconImages/CampingZone.png", name: 'Camping Zone', alt: 'PMRDA sanctioned'},
    { icon: "/AmenityIconImages/Clubhouse.png", name: 'Clubhouse', alt: 'residential projects near Hinjewadi'},
    { icon: "/AmenityIconImages/EndtoEndAssistance.png", name: 'End To End Assistance', alt: 'villa, plots community'},
    { icon: "/AmenityIconImages/GrandEntrance.png", name: 'Grand Entrance', alt: 'land plots near pune'},
    { icon: "/AmenityIconImages/Gardening.png", name: 'Gardening', alt: 'residential projects near Pune' },
    { icon: "/AmenityIconImages/Rainwater.png", name: 'Rainwater Harvesting', alt: 'RERA registered plots near Pune' },
    { icon: "/AmenityIconImages/SewageTreatment.png", name: 'Sewage Treatment', alt: 'Residential Plots near Pune' },
    { icon: "/AmenityIconImages/StreetLighting.png", name: 'Street Lights', alt: 'Residential Plots near Hinjewadi' },
    { icon: "/AmenityIconImages/TelepresenceRoom.png", name: 'Telepresence Room', alt: 'Plots near Pune' },
    { icon: "/AmenityIconImages/WaterTank.png", name: 'Water Tank', alt: 'Plots near Hinjewadi' },
    // Add more amenities as needed
];


const Amenities = () => {
    return (
        <div id="amenities">

            <div class="amenities-container greenGradient">
                <h1>AMENITIES</h1>
                <h2>A Symphony of Opulence</h2>
                <p>
                   Explore a realm of unmatched luxury at Sylvanscape, where an array of
carefully curated amenities awaits to redefine your lifestyle. <br /> Our PMRDA
sanctioned residential projects near Hinjewadi offer a diverse range of
luxurious living options,<br /> from villas and bungalows near Pune to RERA
registered plots and NA plots near Pune.
                </p>
                <div className="amenities-grid-container">
                    {amenities.map((amenity, index) => (
                        <div key={index} className="amenities-grid-item">
                            <AmenityIcon icon={amenity.icon} name={amenity.name} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Amenities;
