import React from 'react';
import './locationicon.css'; // Import the CSS file

const AmenityIcon = ({ distance, name }) => {
    return (
        <div className="amenity">
            <div className="distance">{distance}</div>

            <hr className="separator" />
            <div className="name">{name}</div>
        </div>
    );
};

export default AmenityIcon;
