import React from 'react'
import '../Community/community.css'
import manImage from '../Community/EleganceredefinedimageFinalImage.png'

function Community() {
  return (
    <div id="community">

      <div className='community-container'>
        <div className='container-fluid manContainer desktopView'>
          <div className='row greenGradient imgD'>
            <div className='col-lg-12 imgD'>
              <div className='textOverlay'>
                <h3>Plot Community of Exclusive Villa and NRIs</h3>
                <h2 style={{ textDecoration: 'underline', textDecorationThickness: '3px', textUnderlineOffset: '10px' }}>ELEGANCE REDEFINED</h2>
              </div>
              <div className='manTextOverlay'>
                { }
                <p>At Sylvanscape, our vision transcends traditional residential<br />development. We aspire to curate a haven for the<br />discerning - an exclusive community for Exclusives and NRIs<br />seeking a graceful lifestyle.<br /></p><br />
                <p>Join a community that mirrors your refined taste. With  <br /> RERA
                    registered plots and PMRDA sanctioned properties, <br /> each villa plot in
                    our community is carefully curated to<br /> combine the beauty of nature
                    with the luxury of modern living. <br /></p>
                <p>At Sylvanscape, each villa plot is a canvas for your dream<br />home, where nature blends seamlessly with opulence.<br /></p>
                <p>Sylvanscape is not just a residential project; it's a vision brought <br /> to life for those who seek a life of grace and tranquility.</p>
              </div>
              <img src={manImage} alt="PMRDA sanctioned Plots" className="image manImage" width={'50%'} style={{ float: 'right' }} />
            </div>
          </div>

        </div>
        <div className='container-fluid manContainer mobileView'>
          <div className='row greenGradient imgD'>
            <div className='col-lg-12 imgD'>
              <div className='textOverlay'>
                <h3>COMMUNITY OF EXCLUSIVEs & NRIs</h3>
                <h2 style={{ textDecoration: 'underline', textDecorationThickness: '2.5px', textUnderlineOffset: '5px' }}>ELEGANCE REDEFINED</h2><br />
              </div><center>
                <div className='manTextOverlay'>
                  {/* <p>At Sylvanscape, our vision transcends traditional residential development. We aspire to curate a haven for the discerning - an exclusive community for Exclusives and NRIs seeking a graceful lifestyle.<br />Join a community that mirrors your refined taste. At Sylvanscape, each villa plot is a canvas for your dream home, where nature blends seamlessly with opulence.<br />Sylvanscape is not just a residential project; it's a vision brought to life for those who seek a life of grace and tranquility.</p> */}
                  <p>Sylvanscape's path breaking Managed<br />Forest Concept is a symphony of nature and<br />design. With precision planning, we've<br />created an environment where 2500+ trees<br />coexist seamlessly with your living space.</p><br />
                  <p>Each tree is strategically placed to ensure<br />that your home receives ample sunlight,<br />fresh air, and unobstructed views of nature.<br />The result is a living space where the beauty<br />of the forest enhances your lifestyle,<br />creating a serene and refreshing<br />atmosphere every day.</p>
                  <p style={{ padding: '20px', paddingBottom: '100px' }}>Sylvanscape is not just a residential project;<br />it's a visionbrought to life for those who seek a life of grace and tranquility.</p>
                </div></center>
              <img src={manImage} alt="PMRDA sanctioned Plots" className="image manImage" width={'50%'} style={{ float: 'right' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Community